<template>
  <b-container fluid class="page-static-style">
    <div class="menu-corner m-2">
      <b-sidebar id="sidebar-backdrop1" backdrop shadow>
        <div class="ml-3 filtre-mobile">
          <b-col lg="3" sm="12" class="mb-1 mt-1">
            <div class="box-label-champ mr-2">
              <div class="label-box-style w-93-px">
                <span class="title-tabel">Date début</span>
              </div>
              <date-picker
                value-type="format"
                type="date"
                v-model="date_debut"
                :clear-button="true"
                :disabled-date="disabledStartDateFilter"
                input-class="custom-date-picker-filter-statistcs"
                class="custom-date-picker-calender-filter"
                @change="changeFiltre"
              ></date-picker>
            </div>
          </b-col>
          <b-col lg="3" sm="12" class="mb-1 mt-1"
            ><div class="box-label-champ mr-2">
              <div class="label-box-style w-93-px">
                <span class="title-tabel">Date fin</span>
              </div>
              <date-picker
                input-class="custom-date-picker-filter-statistcs"
                class="custom-date-picker-calender-filter"
                value-type="format"
                type="date"
                v-model="date_fin"
                :clear-button="true"
                :disabled-date="disabledEndDateFiltre"
                @change="changeFiltre"
              ></date-picker></div
          ></b-col>
          <b-col lg="4" sm="12" class="mb-1 mt-1">
            <filter-component label="Famille" classLabel="w-100-px">
              <template v-slot:body>
                <multiselect
                  v-model="famille"
                  @input="changeFiltre"
                  :multiple="true"
                  :options="computedListFamille"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Famille</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Familles</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
          </b-col>
          <b-col lg="3" sm="12" class="mb-1 mt-1">
            <filter-component label="Vue à afficher" classLabel="w-126-px">
              <template v-slot:body>
                <multiselect
                  v-model="vueToDisplay"
                  track-by="value"
                  label="text"
                  :options="vueOptions"
                  :searchable="false"
                  class="customSelectSingleFilter"
                  placeholder="Sélectionner"
                  :showLabels="false"
                  @input="onChangeVueToDisplay"
                >
                </multiselect>
              </template>
            </filter-component>
          </b-col>
        </div>
        <b-col lg="2" sm="12" class="p-1">
          <div class="ml-3 style-static-page">
            <div class="Search-style-component">
              <div class="search-box">
                <font-awesome-icon icon="search" class="icon" />
                <input
                  class="search-input"
                  placeholder="Chercher"
                  v-model="search"
                />
              </div>
            </div>
            <div
              v-if="loderSociete"
              class="d-flex align-items-center justify-content-center"
            >
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
            <ul class="ul-societe-style">
              <li
                v-for="(item, index) in computedFiltreSearchSociete"
                :key="index"
                class="li-societe-style"
                :class="{
                  'li-societe-style-active': activeItem(item) === true
                }"
                @click="clickItemList(item)"
              >
                {{ item.text }}
                <font-awesome-icon
                  v-if="activeItem(item) === true"
                  icon="check-circle"
                  class="check-icon-style"
                />
              </li>
            </ul>
          </div> </b-col
      ></b-sidebar>

      <button
        class="icon button-filtre-style mr-2 p-2"
        v-b-toggle.sidebar-backdrop1
      >
        Afficher sociétés & Fitres
        <font-awesome-icon icon="caret-right" class="color-icon" />
      </button>
    </div>
    <b-row class="pt-1 filtres">
      <b-col lg="2" sm="12">
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date début</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            v-model="date_debut"
            :clear-button="true"
            :disabled-date="disabledStartDateFilter"
            input-class="custom-date-picker-filter-statistcs"
            class="custom-date-picker-calender-filter"
            @change="changeFiltre"
          ></date-picker>
        </div>
      </b-col>
      <b-col lg="2" sm="12"
        ><div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date fin</span>
          </div>
          <date-picker
            input-class="custom-date-picker-filter-statistcs"
            class="custom-date-picker-calender-filter"
            value-type="format"
            type="date"
            v-model="date_fin"
            :clear-button="true"
            :disabled-date="disabledEndDateFiltre"
            @change="changeFiltre"
          ></date-picker></div
      ></b-col>
      <b-col lg="3" sm="12"
        ><filter-component label="Famille" classLabel="w-100-px">
          <template v-slot:body>
            <multiselect
              v-model="famille"
              :multiple="true"
              :options="[{ text: 'Tous', value: null }, ...computedListFamille]"
              @input="changeFiltreForFamille($event)"
              label="text"
              track-by="value"
              class="customSelectMultipleFilter"
              :showLabels="false"
              placeholder="sélectionner"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  :class="{
                    'multiselect__single showBlock': !isOpen,
                    'multiselect__single ': isOpen
                  }"
                  v-if="values.length && values.some(e => e.text != 'Tous')"
                  >{{ values.length }} options selected</span
                >
                <span
                  :class="{
                    'multiselect__single showBlock': !isOpen,
                    'multiselect__single ': isOpen
                  }"
                  v-if="values.some(e => e.text == 'Tous')"
                  >Tous selectionnée</span
                >
              </template>
            </multiselect></template
          >
        </filter-component>
      </b-col>
      <b-col lg="3" sm="12">
        <filter-component label="Vue à afficher" classLabel="w-126-px">
          <template v-slot:body>
            <multiselect
              v-model="vueToDisplay"
              track-by="value"
              label="text"
              :options="vueOptions"
              :searchable="false"
              class="customSelectSingleFilter"
              placeholder="Sélectionner"
              :showLabels="false"
              @input="onChangeVueToDisplay"
            >
            </multiselect>
          </template>
        </filter-component>
      </b-col>
      <b-col lg="1" sm="12">
        <div v-if="getStatsLoading" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </b-col>
      <b-col lg="2" sm="12"></b-col>
    </b-row>
    <b-row style="background-color: #f6f5fb">
      <b-col lg="2" sm="12" class="p-1 list-societe">
        <div class="ml-3 side-bar-position style-static-page">
          <div class="Search-style-component">
            <div class="search-box">
              <font-awesome-icon icon="search" class="icon" />
              <input
                class="search-input"
                placeholder="Chercher"
                v-model="search"
              />
            </div>
          </div>
          <div
            v-if="loderSociete"
            class="d-flex align-items-center justify-content-center"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder"
            />
          </div>
          <ul class="ul-societe-style">
            <li
              v-for="(item, index) in computedFiltreSearchSociete"
              :key="index"
              class="li-societe-style"
              :class="{
                'li-societe-style-active': activeItem(item) === true
              }"
              @click="clickItemList(item)"
            >
              {{ item.text }}
              <font-awesome-icon
                v-if="activeItem(item) === true"
                icon="check-circle"
                class="check-icon-style"
              />
            </li>
          </ul>
        </div>
      </b-col>
      <b-col
        lg="10"
        sm="12"
        class="right-side style-static-page pr-4 pl-1 pt-1 pb-1 mt-1"
      >
        <div class="d-flex">
          <p class="ml-2 mb-0" v-if="societe.length">Sociétés sélectionnées</p>
          <font-awesome-icon
            v-if="societe.length"
            icon="times"
            class="time-style-global ml-1 mt-1"
            @click="ClearSocieteFiltre"
          />
        </div>
        <b-row class="ml-2 mr-3"
          ><ul class="ul-societe-style-display mb-0">
            <li
              v-for="(item, index) in societe"
              :key="index"
              class="li-societe-style-active"
              @click="clickItemList(item)"
            >
              {{ item.text }}
              <font-awesome-icon icon="times" class="time-style" />
            </li></ul
        ></b-row>
        <b-row class="ml-2 mr-4 mt-0 mb-0 calcul-general-label"
          ><b class="text-white ml-1 p-1"> Calcul Général </b></b-row
        >
        <b-row class="mt-1 ml-2 mr-4 mb-1 white-background">
          <b-col lg="6" sm="12">
            <b> Total cumule à encaisser </b>
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 Montant-labels">
                <p class="font-style mb-1">Montant TTC Factures Non Payées</p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres"
                  >{{
                    (
                      Math.round(
                        computedSommeTotalNonPayerTTC('societe') * 100
                      ) / 100
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
            <hr class="mt-1 mb-1" />
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 Montant-labels">
                <p class="font-style mb-1">Montant TTC Factures Payées</p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres"
                  >{{
                    (
                      Math.round(computedSommeTotalPayerTTC('societe') * 100) /
                      100
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
            <hr class="mt-1 mb-1" />
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 chiffre-affaire-label">
                <p class="font-style mb-1">
                  Chiffres d'affaires de la période (HT)
                </p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres">
                  {{
                    (Math.round(computedSommeTotalHT('societe') * 100) / 100)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 chiffre-affaire-label">
                <p class="font-style mb-1">
                  Chiffres d'affaires de la période (TTC)
                </p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres">
                  {{
                    (Math.round(computedSommeTotalTTC('societe') * 100) / 100)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" sm="12">
            <b>Total cumule à décaisser</b>
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 Montant-labels">
                <p class="font-style mb-1">Montant TTC Factures Non Payées</p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres">
                  {{
                    (
                      Math.round(
                        computedSommeTotalNonPayerTTC('client') * 100
                      ) / 100
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
            <hr class="mt-1 mb-1" />
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 Montant-labels">
                <p class="font-style mb-1">Montant TTC Factures Payées</p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres">
                  {{
                    (
                      Math.round(computedSommeTotalPayerTTC('client') * 100) /
                      100
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
            <hr class="mt-1 mb-1" />
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 chiffre-affaire-label">
                <p class="font-style mb-1">Charge de la période (HT)</p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres">
                  {{
                    (Math.round(computedSommeTotalHT('client') * 100) / 100)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-6 mt-1 mb-1 pl-1 pr-0 chiffre-affaire-label">
                <p class="font-style mb-1">Charge de la période (TTC)</p>
              </b-col>
              <b-col class="col-5 mt-1 mb-1 pl-0 pr-0 chiffre-label">
                <div v-if="getStatsLoading">
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    :color="'#9799d6'"
                    class="loading-details-frais"
                  />
                </div>
                <b v-else class="ml-2 aligne-chiffres">
                  {{
                    (Math.round(computedSommeTotalTTC('client') * 100) / 100)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      .replaceAll(',', ' ')
                  }}
                  €</b
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          class="
            d-flex
            align-items-center
            justify-content-center
            mt-3
            mr-3
            ml-2
            alert-div-style
          "
          v-if="societe.length === 0"
        >
          <p class="text-alert-color">Sélectionner au moins une sociéte</p>
        </b-row>
        <b-row
          v-if="
            societe.length > 0 &&
              (getStatsAcheteur.length > 0 || getStatsVendeur.length > 0)
          "
          class="ml-2 mr-4 mt-0 mb-0 Calcul Général"
          ><b class="text-white ml-1"> Courbes </b></b-row
        >
        <!-- global charts -->
        <b-row
          v-if="
            societe.length > 0 &&
              vueToDisplay &&
              vueToDisplay.value === 'global'
          "
          class="mb-3"
        >
          <b-col lg="6" sm="12">
            <div
              class="child--100 ml-2"
              v-for="(item, index) in getStatsAcheteur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures émiseses) </span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                width="100%"
                :height="computedHeightcharts(index)"
                :options="computedSociteClientsOptions(item.filiale)"
                :series="computedSociteClientsSeries(item.filiale)"
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
          <b-col lg="6" sm="12">
            <div
              class="child--100"
              v-for="(item, index) in getStatsVendeur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures reçues)</span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                width="100%"
                :height="computedHeightcharts(index)"
                :options="computedSociteVendeursOptions(item.filiale)"
                :series="computedSociteVendeursSeries(item.filiale)"
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
        </b-row>
        <!-- payer charts -->
        <b-row
          v-if="
            societe.length > 0 && vueToDisplay && vueToDisplay.value === 'payer'
          "
          class="mb-3"
        >
          <b-col lg="6" sm="12">
            <div
              class="child--100 ml-2"
              v-for="(item, index) in getStatsAcheteur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures émises) </span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                :height="computedHeightcharts(index)"
                width="100%"
                :options="computedSociteClientsOptions(item.filiale)"
                :series="computedSociteClientsPayerSeries(item.filiale)"
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
          <b-col lg="6" sm="12">
            <div
              class="child--100"
              v-for="(item, index) in getStatsVendeur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures reçues)</span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                :height="computedHeightcharts(index)"
                width="100%"
                :options="computedSociteVendeursOptions(item.filiale)"
                :series="computedSociteVendeursPayerSeries(item.filiale)"
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
        </b-row>
        <!-- payer charts -->
        <b-row
          v-if="
            societe.length > 0 &&
              vueToDisplay &&
              vueToDisplay.value === 'a_payer'
          "
          class="mb-3"
        >
          <b-col lg="6" sm="12">
            <div
              class="child--100 ml-2"
              v-for="(item, index) in getStatsAcheteur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures émises) </span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                :height="computedHeightcharts(index)"
                width="100%"
                :options="computedSociteClientsOptions(item.filiale)"
                :series="computedSociteClientsNonPayerSeries(item.filiale)"
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
          <b-col lg="6" sm="12">
            <div
              class="child--100"
              v-for="(item, index) in getStatsVendeur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures reçues)</span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                :height="computedHeightcharts(index)"
                width="100%"
                :options="computedSociteVendeursOptions(item.filiale)"
                :series="computedSociteVendeursNonPayerSeries(item.filiale)"
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
        </b-row>
        <b-row
          v-if="
            societe.length > 0 &&
              vueToDisplay &&
              vueToDisplay.value === 'payerNonPayer'
          "
          class="mb-3"
        >
          <b-col lg="6" sm="12">
            <div
              class="child--100 ml-2"
              v-for="(item, index) in getStatsAcheteur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures émises) </span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                :height="computedHeightcharts(index)"
                width="100%"
                :options="computedSociteClientsOptions(item.filiale)"
                :series="
                  computedSocieteClientsPayerNonPayerSeries(item.filiale)
                "
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
          <b-col lg="6" sm="12">
            <div
              class="child--100"
              v-for="(item, index) in getStatsVendeur"
              :key="index"
            >
              <div class="chart-title">
                <span class="text"> {{ item.name }} (Factures reçues)</span>
              </div>
              <apexchart
                v-if="item.filiale.length > 0"
                type="bar"
                :height="computedHeightcharts(index)"
                width="100%"
                :options="computedSociteVendeursOptions(item.filiale)"
                :series="computedSociteVendeurPayerNonPayerSeries(item.filiale)"
              ></apexchart>
              <div
                v-else
                :style="'height :' + (computedHeightcharts(index) + 15) + 'px;'"
              >
                <p class="aligne-empty-bills">Pas des factures</p>
              </div>
            </div></b-col
          >
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
// import { CheckboxSvgMap } from 'vue-svg-map';
import FranceDepartement from '@svg-maps/france.departments';
import FilterComponent from './ThProject/frais/component/filterComponent.vue';
// import ICountUp from 'vue-countup-v2';
export default {
  name: 'StatisticsGeneral',
  components: {
    apexchart: VueApexCharts,
    // CheckboxSvgMap: CheckboxSvgMap,
    // ICountUp
    FilterComponent
    // Card: () => import('../views/component/card.vue')
  },
  data: function() {
    return {
      vueToDisplay: { text: 'Factures à payer', value: 'a_payer' },
      vueOptions: [
        { text: 'Factures à payer', value: 'a_payer' },
        { text: 'Factures Payeés', value: 'payer' },
        { text: 'État Global', value: 'global' },
        { text: 'État de paiement', value: 'payerNonPayer' }
      ],
      allSelected: false,
      famille: [],
      loderSociete: false,
      years: [],
      year: null,
      all: false,
      month: null,
      week: null,
      filiale: null,
      depot: null,
      chartParMoisNombreSeries: [],
      chartParMoisNombreCategories: [],
      chartParMapCategories: [],
      chartParMoisMontantSeries: [],
      chartParMoisMontantCategories: [],
      chartInstallateurNombreSeries: [],
      chartInstallateurNombreCategories: [],
      chartInstallateurMontantSeries: [],
      chartInstallateurMontantCategories: [],
      chartFilialeSeries: [],
      chartFilialeCategories: [],
      tableFilialeItems: [],
      tableSupportItems: [],
      date_debut: null,
      date_fin: null,
      search: null,
      societe: [],
      tableDepartementItems: [],
      chartDepartementNbrDossierSeries: [],
      chartDepartementNbrDossierSeriesSvg: [],
      seriesChartMontantDepotSvg: [],
      chartDepartementMontantSeries: [],
      support: null,
      currentState: false,
      currentStateDepot: false,
      currentStateFacture: false,
      FranceDepartement,
      selectedLocations: [],
      depot_stockage: null,
      ariaLabel: null,
      pointedLocation: null,
      focusedLocation: null,
      SelectedLocationName: [],

      leftStyle: 'left: 0px',
      topStyle: ' top : 0px',
      departmentsListe: null,
      hideGlobalBlock: false,
      delay: 0,
      endVal: null,
      optionsMontant: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '€'
      },
      optionsNbr: {
        useEasing: false,
        useGrouping: false,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    };
  },
  computed: {
    ...mapGetters([
      'getMonthForYearTh',
      'getcomputedListWeek',
      'getcomputedFiliale',
      'getcomputedDepots',
      'getStatsLoading',
      'getStatsPerMonthLoading',
      'getStatsPerFilialeLoading',
      'getStatsDataPerMonth',
      'getStatsAcheteur',
      'getStatsVendeur',
      'getStatsDataPerFiliale',
      'getStatsTotalProject',
      'getStatsNombreFactureTotale',
      'getStatsSommeFactureTotale',
      'getUserData',
      'getAllDepartements',
      'getStatsDataPerMap',
      'getLoadingPerMap',
      'familles',
      'getErrorDataPerMap',
      'getStatsDataPerMapGlobal',
      'getFiliaeOfResponsable',
      'getFactureLibreLoadingTh'
    ]),
    computedHeightblock() {
      return function(i) {
        if (this.computedChartClient(i) > this.computedChartSociete(i)) {
          return 'height:' + this.computedChartClient(i) + 'px';
        } else {
          return 'height:' + this.computedChartSociete(i) + 'px';
        }
      };
    },
    computedHeightcharts() {
      return function(i) {
        if (
          this.getStatsAcheteur[i]?.filiale?.length === 0 &&
          this.getStatsVendeur[i]?.filiale?.length === 0
        ) {
          return 40;
        } else {
          if (this.computedChartClient(i) > this.computedChartSociete(i)) {
            return this.computedChartClient(i) + 100;
          } else {
            return this.computedChartSociete(i) + 100;
          }
        }
      };
    },
    computedChartClient() {
      return function(i) {
        return this.getStatsAcheteur[i]?.filiale?.length * 40;
      };
    },
    computedChartSociete() {
      return function(i) {
        return this.getStatsVendeur[i]?.filiale?.length * 40;
      };
    },
    computedListFamille() {
      let familles = [];
      for (let i = 0; i < this.familles.length; i++) {
        familles.push({
          value: this.familles[i],
          text: this.familles[i]
        });
      }
      return familles;
    },
    computedSociteVendeursOptions() {
      return function(item) {
        let options = {};
        let labelSociete = [];
        item.map(element => labelSociete.push(element.name));
        options = {
          chart: {
            id: 'vuechart-example',
            toolbar: {
              show: false
            }
          },
          legend: {
            position: 'top',
            show: true
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'bottom', // top, center, bottom,
                offsetY: 0,
                offset: 0
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: 10,
            style: {
              fontSize: '10px',
              colors: ['#000']
            },
            formatter: val => this.formatNumber(val)
          },
          xaxis: {
            categories: labelSociete
          }
        };
        return options;
      };
    },
    computedSociteVendeursSeries() {
      return function(item) {
        let series = [];
        // let dataSocieteHT = [];
        let dataSocieteTTC = [];
        // item.map(element =>
        //   dataSocieteHT.push(Math.round(element.total_net_ht * 100) / 100)
        // );
        item.map(element =>
          dataSocieteTTC.push(Math.round(element.total_ttc * 100) / 100)
        );
        series = [
          // {
          //   name: 'Montant HT',
          //   data: dataSocieteHT,
          //   color: '#26a0fc'
          // },
          {
            name: 'Montant TTC',
            data: dataSocieteTTC,
            color: '#26e7a6'
          }
        ];
        return series;
      };
    },
    computedSociteVendeursPayerSeries() {
      return function(item) {
        let series = [];
        // let dataSocieteHT = [];
        let dataSocieteTTC = [];
        // item.map(element =>
        //   dataSocieteHT.push(Math.round(element.total_payee_net_ht * 100) / 100)
        // );
        item.map(element =>
          dataSocieteTTC.push(Math.round(element.total_payee_ttc * 100) / 100)
        );
        series = [
          // {
          //   name: 'Montant HT',
          //   data: dataSocieteHT,
          //   color: '#26a0fc'
          // },
          {
            name: 'Montant TTC',
            data: dataSocieteTTC,
            color: '#26e7a6'
          }
        ];
        return series;
      };
    },
    computedSociteVendeursNonPayerSeries() {
      return function(item) {
        let series = [];
        // let dataSocieteHT = [];
        let dataSocieteTTC = [];
        // item.map(element =>
        //   dataSocieteHT.push(
        //     Math.round(element.total_non_payee_net_ht * 100) / 100
        //   )
        // );
        item.map(element =>
          dataSocieteTTC.push(
            Math.round(element.total_non_payee_ttc * 100) / 100
          )
        );
        series = [
          // {
          //   name: 'Montant HT',
          //   data: dataSocieteHT,
          //   color: '#26a0fc'
          // },
          {
            name: 'Montant TTC',
            data: dataSocieteTTC,
            color: '#FA7070',
            show: true
          }
        ];
        return series;
      };
    },
    computedSociteClientsOptions() {
      return function(item) {
        let options = {};
        let labelSociete = [];
        item.map(element => labelSociete.push(element.name));
        options = {
          chart: {
            id: 'vuechart-example',
            toolbar: {
              show: false
            }
          },
          legend: {
            position: 'top',
            show: true
          },
          plotOptions: {
            bar: {
              horizontal: true,
              columnWidth: '100%',
              dataLabels: {
                position: 'bottom', // top, center, bottom,
                offsetY: 5,
                offset: 30,
                minAngleToShowLabel: 10
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: 10,
            style: {
              fontSize: '10px',
              colors: ['#000']
            },
            formatter: val => this.formatNumber(val)
          },

          xaxis: {
            categories: labelSociete
          }
        };
        return options;
      };
    },
    computedSociteClientsSeries() {
      return function(item) {
        let series = [];
        // let dataSocieteHT = [];
        let dataSocieteTTC = [];
        // item.map(element =>
        //   dataSocieteHT.push(Math.round(element.total_net_ht * 100) / 100)
        // );
        item.map(element =>
          dataSocieteTTC.push(Math.round(element.total_ttc * 100) / 100)
        );
        series = [
          // {
          //   name: 'Montant HT',
          //   data: dataSocieteHT,
          //   color: '#26a0fc'
          // },
          {
            name: 'Montant TTC',
            data: dataSocieteTTC,
            color: '#26e7a6'
          }
        ];
        return series;
      };
    },
    computedSociteClientsPayerSeries() {
      return function(item) {
        let series = [];
        let dataSocieteHT = [];
        let dataSocieteTTC = [];
        item.map(element =>
          dataSocieteHT.push(Math.round(element.total_payee_net_ht * 100) / 100)
        );
        item.map(element =>
          dataSocieteTTC.push(Math.round(element.total_payee_ttc * 100) / 100)
        );
        series = [
          // {
          //   name: 'Montant HT',
          //   data: dataSocieteHT,
          //   color: '#26a0fc'
          // },
          {
            name: 'Montant TTC',
            data: dataSocieteTTC,
            color: '#26e7a6'
          }
        ];
        return series;
      };
    },
    computedSocieteClientsPayerNonPayerSeries() {
      return function(item) {
        let series = [];
        let dataSocieteHT = [];
        let dataSocieteTTC = [];
        item.map(element =>
          dataSocieteHT.push(
            Math.round(element.total_non_payee_ttc * 100) / 100
          )
        );
        item.map(element =>
          dataSocieteTTC.push(Math.round(element.total_payee_ttc * 100) / 100)
        );
        series = [
          {
            name: 'Montant a payer TTC',
            data: dataSocieteHT,
            color: '#FA7070'
          },
          {
            name: 'Montant payé TTC',
            data: dataSocieteTTC,
            color: '#46ebb3'
          }
        ];
        return series;
      };
    },
    computedSociteVendeurPayerNonPayerSeries() {
      return function(item) {
        let series = [];
        let dataSocieteHT = [];
        let dataSocieteTTC = [];
        item.map(element =>
          dataSocieteHT.push(
            Math.round(element.total_non_payee_ttc * 100) / 100
          )
        );
        item.map(element =>
          dataSocieteTTC.push(Math.round(element.total_payee_ttc * 100) / 100)
        );
        series = [
          {
            name: 'Montant a payer TTC',
            data: dataSocieteHT,
            color: '#FA7070'
          },
          {
            name: 'Montant payé TTC',
            data: dataSocieteTTC,
            color: '#46ebb3'
          }
        ];
        return series;
      };
    },
    computedSociteClientsNonPayerSeries() {
      return function(item) {
        let series = [];
        let dataSocieteHT = [];
        let dataSocieteTTC = [];
        item.map(element =>
          dataSocieteHT.push(
            Math.round(element.total_non_payee_net_ht * 100) / 100
          )
        );
        item.map(element =>
          dataSocieteTTC.push(
            Math.round(element.total_non_payee_ttc * 100) / 100
          )
        );
        series = [
          // {
          //   name: 'Montant HT',
          //   data: dataSocieteHT,
          //   color: '#26a0fc'
          // },
          {
            name: 'Montant TTC',
            data: dataSocieteTTC,
            color: '#FA7070'
          }
        ];
        return series;
      };
    },
    computedSommeTotalNonPayer() {
      return function() {
        let somme = 0;
        if (this.societe.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(
                    item.factures_vendeur_non_payee_total_net_ht * 100
                  ) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(
                    item.factures_acheteur_non_payee_total_net_ht * 100
                  ) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalNonPayerTTC() {
      return function(type) {
        let somme = 0;
        if (this.societe.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_non_payee_total_ttc * 100) /
                  100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_non_payee_total_ttc * 100) /
                  100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalTTC() {
      return function(type) {
        let somme = 0;
        if (this.societe.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item => (somme += Math.round(item.total_ttc_filiale * 100) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item => (somme += Math.round(item.total_ttc_filiale * 100) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalHT() {
      return function(type) {
        let somme = 0;
        if (this.societe.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme += Math.round(item.total_net_ht_filiale * 100) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme += Math.round(item.total_net_ht_filiale * 100) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalPayerTTC() {
      return function(type) {
        let somme = 0;
        if (this.societe.length) {
          if (type === 'societe') {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_payee_total_ttc * 100) / 100)
            );
          } else {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(item.factures_filiale_payee_total_ttc * 100) / 100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computedSommeTotalPayer() {
      return function(type) {
        let somme = 0;
        if (this.societe.length) {
          if (type === 'societe') {
            this.getStatsVendeur.map(
              item =>
                (somme +=
                  Math.round(item.factures_vendeur_payee_total_net_ht * 100) /
                  100)
            );
          } else {
            this.getStatsAcheteur.map(
              item =>
                (somme +=
                  Math.round(item.factures_acheteur_payee_total_net_ht * 100) /
                  100)
            );
          }
          return somme;
        } else {
          return 0;
        }
      };
    },
    computegetSettingFilialesTh() {
      let vendeurNames = [];
      this.getFiliaeOfResponsable.map(item => {
        if (item.name !== null) {
          vendeurNames.push({ value: item.id, text: item.name });
        }
      });
      return vendeurNames;
    },
    computedFiltreSearchSociete() {
      if (this.search === null) {
        return this.computegetSettingFilialesTh;
      } else {
        let societeToDisplay = this.computegetSettingFilialesTh.filter(item =>
          item?.text?.toLowerCase().includes(this.search.toLowerCase())
        );
        return societeToDisplay;
      }
    },
    activeItem() {
      return function(item) {
        const index = this.societe.findIndex(
          element => element.value === item.value
        );
        if (index === -1) {
          return false;
        } else {
          return true;
        }
      };
    },

    computedListType() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_novae'
      ) {
        return this.listType.filter(
          item => item.value == 'NOVA' || item.value == 'NOVAE MAIRIE'
        );
      }
      return [{ value: null, text: 'Tous les supports' }, ...this.listType];
    },
    getAllFranceDepartement() {
      return ['Tous les dépots', ...this.getAllDepartements];
    }
  },
  watch: {
    all: function() {
      if (this.all === true) {
        this.famille = this.computedListFamille;
        this.loadStatsAcheteur();
        this.loadStatsVendeur();
      } else {
        this.famille = [];
        this.loadStatsAcheteur();
        this.loadStatsVendeur();
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchStatsAcheteur',
      'fetchStatsVendeur',
      'fetchStatsPerMonth',
      'fetchStatsPerFiliale',
      'featchDepotForYearTH',
      'fetchMonthForYearTH',
      'fetchSemaineValiderTh',
      'fetchAllDepartements',
      'fetchStatsParMap',
      'fetchStatsParMapGlobal',
      'getFilialsOfConnectedResponsable',
      'getFamilles',
      'initDataAcheteur',
      'initDataVendeur'
    ]),

    ClearSocieteFiltre() {
      this.societe = [];
      this.initDataAcheteur();
      this.initDataVendeur();
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    clickItemList(item) {
      const index = this.societe.findIndex(
        element => element.value === item.value
      );
      if (index !== -1) {
        this.societe.splice(index, 1);
        if (this.societe.length > 0) {
          this.loadStatsAcheteur();
          this.loadStatsVendeur();
        }
        if (this.societe.length === 0) {
          this.initDataAcheteur();
          this.initDataVendeur();
        }
      } else {
        this.societe.push(item);
        this.loadStatsAcheteur();
        this.loadStatsVendeur();
      }
    },
    changeFiltre() {
      this.loadStatsAcheteur();
      this.loadStatsVendeur();
    },
    changeFiltreForFamille(event) {
      if (event[event.length - 1].value === null) {
        if (
          event.filter(item => item.value !== null)?.length ===
          this.computedListFamille.length
        ) {
          this.famille = [];
          this.loadStatsAcheteur();
          this.loadStatsVendeur();
        } else {
          this.famille = this.computedListFamille;
          this.loadStatsAcheteur();
          this.loadStatsVendeur();
        }
      } else {
        this.loadStatsAcheteur();
        this.loadStatsVendeur();
      }
    },
    onChangeVueToDisplay() {
      this.loadStatsAcheteur();
      this.loadStatsVendeur();
    },
    disabledStartDateFilter(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDateFiltre(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    onYearChange() {
      this.week = null;
      this.month = null;
      this.depot = null;
      this.filterOption = true;
      if (!this.computedRoleFiliale) {
        this.filiale = null;
      }

      // this.fetchMonthForYearTH(this.year);
      // this.featchDepotForYearTH(this.year);
      // this.loadStats();
      // this.loadStatsPerMonth();
      // this.loadStatsPerFiliale();

      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      // this.setLocalStorageStats();
    },

    formatNumber(number) {
      if (!number) {
        return '0 €';
      }
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }).format(number);
    },
    async loadStatsAcheteur() {
      var bodyFormData = new FormData();
      if (this.date_debut !== null) {
        bodyFormData.append('date_debut', this.date_debut);
      }
      if (this.vueToDisplay !== null) {
        bodyFormData.append('vue', this.vueToDisplay.value);
      }
      if (this.famille.length) {
        for (let i = 0; i < this.famille.length; i++) {
          bodyFormData.append('famille[' + i + ']', this.famille[i].value);
        }
      }
      if (this.date_fin !== null) {
        bodyFormData.append('date_fin', this.date_fin);
      }
      for (let i = 0; i < this.societe.length; i++) {
        bodyFormData.append('ids[' + i + ']', this.societe[i].value);
      }
      bodyFormData.append('type', 'Acheteur');

      await this.fetchStatsAcheteur(bodyFormData);
    },
    async loadStatsVendeur() {
      var bodyFormData = new FormData();
      if (this.date_debut !== null) {
        bodyFormData.append('date_debut', this.date_debut);
      }
      if (this.vueToDisplay !== null) {
        bodyFormData.append('vue', this.vueToDisplay.value);
      }
      if (this.famille.length) {
        for (let i = 0; i < this.famille.length; i++) {
          bodyFormData.append('famille[' + i + ']', this.famille[i].value);
        }
      }
      if (this.date_fin !== null) {
        bodyFormData.append('date_fin', this.date_fin);
      }
      for (let i = 0; i < this.societe.length; i++) {
        bodyFormData.append('ids[' + i + ']', this.societe[i].value);
      }
      bodyFormData.append('type', 'Vendeur');

      await this.fetchStatsVendeur(bodyFormData);
    },
    async loadStatsPerFiliale() {
      await this.fetchStatsPerFiliale({
        year: this.year,
        month: this.month,
        week: this.week,
        filiale: this.filiale,
        depot: this.depot,
        support: this.computedSupport
      });
      const seriesAnnule = [];
      const seriesAPayer = [];
      const seriesNonValide = [];
      const seriesPayer = [];
      const seriesMontantAnnule = [];
      const seriesMontantAPayer = [];
      const seriesMontantNonValide = [];
      const seriesMontantPayer = [];

      const categories = [];

      let totalPayee = 0;
      let totalAPayer = 0;
      let totalAnnulee = 0;
      let totalNonValide = 0;
      let totalFactureTotal = 0;
      let totalMontantTotal = 0;
      let totalPayeeMontant = 0;
      let totalaPayeeMontant = 0;

      this.tableFilialeItems = this.getStatsDataPerFiliale.map(item => {
        categories.push(item.name);
        seriesAnnule.push(item.annulee?.nombre_facture || 0);
        seriesAPayer.push(item.a_paye?.nombre_facture || 0);
        seriesNonValide.push(item.facture_non_valide?.nombre_facture || 0);
        seriesPayer.push(item.paye?.nombre_facture || 0);

        seriesMontantAnnule.push(item.annulee?.montant || 0);
        seriesMontantAPayer.push(item.a_paye?.montant || 0);
        seriesMontantNonValide.push(item.facture_non_valide?.montant || 0);
        seriesMontantPayer.push(item.paye?.montant || 0);

        totalPayee += item.paye.nombre_facture;
        totalPayeeMontant += parseFloat(item.paye.montant);
        totalaPayeeMontant += parseFloat(item.a_paye.montant);
        totalAPayer += item.a_paye.nombre_facture;
        totalAnnulee += item.annulee.nombre_facture;
        totalNonValide += item.facture_non_valide.nombre_facture;
        totalFactureTotal += item.nombre_facture_total;
        totalMontantTotal += parseFloat(item.somme_total);

        return {
          name: item.name,
          paye: item.paye.nombre_facture,
          paye_montant: this.formatNumber(item.paye.montant),
          a_paye: item.a_paye.nombre_facture,
          a_paye_montant: this.formatNumber(item.a_paye.montant),
          annulee: item.annulee.nombre_facture,
          facture_non_valide: item.facture_non_valide.nombre_facture,
          nombre_facture_total: item.nombre_facture_total,
          somme_total: this.formatNumber(item.somme_total)
        };
      });
      this.tableFilialeItems.push({
        name: 'TOTAL',
        paye: totalPayee,
        paye_montant: this.formatNumber(totalPayeeMontant),
        a_paye: totalAPayer,
        a_paye_montant: this.formatNumber(totalaPayeeMontant),
        annulee: totalAnnulee,
        facture_non_valide: totalNonValide,
        nombre_facture_total: totalFactureTotal,
        somme_total: this.formatNumber(totalMontantTotal),
        _rowVariant: 'dark'
      });

      this.chartFilialeSeries = [
        {
          name: 'Payées',
          data: seriesPayer
        },
        {
          name: 'À payer',
          data: seriesAPayer
        },
        {
          name: 'Annulées',
          data: seriesAnnule
        },
        {
          name: 'Factures non validées',
          data: seriesNonValide
        }
      ];

      this.chartFilialeMontantSeries = [
        {
          name: 'Payées',
          data: seriesMontantPayer
        },
        {
          name: 'À payer',
          data: seriesMontantAPayer
        },
        {
          name: 'Annulées',
          data: seriesMontantAnnule
        },
        {
          name: 'Factures non validées',
          data: seriesMontantNonValide
        }
      ];

      this.chartFilialeCategories = categories;
    },
    async loadStatsPerMonth() {
      await this.fetchStatsPerMonth({
        year: this.year,
        month: this.month,
        week: this.week,
        filiale: this.filiale,
        depot: this.depot,
        support: this.computedSupport
      });
      const seriesNombre = [];
      const seriesMontant = [];

      const categories = [];

      this.getStatsDataPerMonth.forEach(item => {
        categories.push(item.name);
        seriesNombre.push(item.total || 0);
        seriesMontant.push(item.somme || 0);
      });

      this.chartParMoisNombreSeries = [
        {
          name: 'Nombre des factures',
          data: seriesNombre
        }
      ];

      this.chartParMoisMontantSeries = [
        {
          name: 'Montant des factures',
          data: seriesMontant
        }
      ];

      this.chartParMoisNombreCategories = categories;
      this.chartParMoisMontantCategories = categories;
    },
    checkRole() {
      if (this.getUserData && this.getUserData.role) {
        switch (this.getUserData.role) {
          case 'responsable_novae':
            this.support = 'NOVA';
            break;
          case 'responsable_cactus':
            this.support = 'CACTUS';
            break;
          case 'responsable_web_abondan':
            this.support = 'AXELIUS';
            break;
          case 'responsable_iso_33':
            this.support = 'ISO 33';
            break;
          case 'responsable_rge':
            this.support = 'CERTILIA';
            break;
          case 'responsable_dnh':
            this.support = 'GE CDG';
            break;
          case 'responsable_sav_s':
            this.support = 'SAMI-B';
            break;
          case 'responsable_web_abondance':
            this.support = 'WEB ABONDANCE';
            break;
          case 'responsable_corpedys':
            this.support = 'CORPEDYS';
            break;
          case 'responsable_acteo':
            this.support = 'ALTEO France';
            break;
          case 'responsable_bureau_etude':
            this.support = 'ISO 33';
            break;
          case 'responsable_mairie':
            this.support = 'NOVAE MAIRIE';
            break;
          case 'responsable_gse':
            this.support = 'GSE';
            break;
          // case 'responsable_upcube_technologies':
          //   this.support = 'upcube';
          //   break;
          // case 'responsable_filiale':
          //   this.filiale = this.getUserData.filiale_name;
        }
      }
    },

    pointLocation(event) {
      this.pointedLocation = event.target.ariaLabel + '-' + event.target.id;
      this.leftStyle = 'left : ' + (event.clientX + 50) + 'px';
      this.topStyle = 'top : ' + event.clientY + 'px';
    },
    unpointLocation(event) {
      this.pointedLocation = null;
    },
    focusLocation(event) {
      this.selectedLocations = [];

      this.departmentsListe = null;
      this.focusedLocation = event.target.ariaLabel;

      this.hideGlobalBlock = true;
    },
    blurLocation(event) {
      this.focusedLocation = null;
    },
    getSelectedLocationName(map, locationId) {
      return (
        locationId &&
        map.locations.find(location => location.id === locationId).name
      );
    },

    async handleGetAllDataGlobal() {
      var params = new FormData();
      // this.selectedLocations = [];
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.depot_stockage !== 'Tous les dépots') {
        params.append('depot_stockage', this.depot_stockage);
      }

      const response = await this.fetchStatsParMapGlobal(params);

      if (response.succes) {
        // for (let i = 0; i < this.$el.querySelectorAll('path').length; i++) {
        //   // Clean location
        //   this.$el.querySelectorAll('path')[i].removeAttribute('aria-checked');
        //   for (let j = 0; j < this.getStatsDataPerMap.length; j++) {
        //     if (
        //       this.$el.querySelectorAll('path')[i].id ==
        //         this.getStatsDataPerMap[j].departement &&
        //       !this.$el
        //         .querySelectorAll('path')
        //         [i].getAttribute('aria-checked') &&
        //       this.$el
        //         .querySelectorAll('path')
        //         [i].getAttribute('aria-checked') !== 'true'
        //     ) {
        //       // Add location
        //       this.$el
        //         .querySelectorAll('path')
        //         [i].setAttribute('aria-checked', true);
        //     }
        //   }
        // }
      }
    },
    async handleGetDepartement() {
      var params = new FormData();
      this.selectedLocations = [];
      this.chartDepartementNbrDossierSeriesSvg = [];
      this.seriesChartMontantDepotSvg = [];
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.depot_stockage !== 'Tous les dépots') {
        params.append('depot_stockage', this.depot_stockage);
      }

      const response = await this.fetchStatsParMap(params);

      if (response.succes && this.depot_stockage) {
        for (let i = 0; i < this.$el.querySelectorAll('path').length; i++) {
          // Clean location
          this.$el.querySelectorAll('path')[i].removeAttribute('aria-checked');

          for (let j = 0; j < this.getStatsDataPerMap.length; j++) {
            if (
              this.$el.querySelectorAll('path')[i].id ==
                this.getStatsDataPerMap[j].departement &&
              !this.$el
                .querySelectorAll('path')
                [i].getAttribute('aria-checked') &&
              this.$el
                .querySelectorAll('path')
                [i].getAttribute('aria-checked') !== 'true'
            ) {
              // Add location

              this.$el
                .querySelectorAll('path')
                [i].setAttribute('aria-checked', true);
            }
          }
        }
      }

      const seriesDossierPayer = [];
      const seriesDossierAttent = [];
      const seriesDepartementAttent = [];
      const seriesDepartementPayer = [];
      const categories = [];
      this.getStatsDataPerMap.forEach(item => {
        categories.push(
          this.getSelectedLocationName(FranceDepartement, item.departement)
        );
        seriesDossierPayer.push(item.paye);
        seriesDossierAttent.push(item.attent);
        seriesDepartementAttent.push(
          item.montant_final_attent.replace(/\s/g, '')
        );
        seriesDepartementPayer.push(item.montant_final_paye.replace(/\s/g, ''));
      });

      this.tableDepartementItems = this.getStatsDataPerMap.map(item => {
        return {
          departement: item.departement,
          depot_stockage: item.depot_stockage,
          paye: item.paye,
          attent: item.attent,
          total: item.total,
          montant_final_attent: item.montant_final_attent + ' €',
          montant_final_paye: item.montant_final_paye + ' €',
          montant_final: item.montant_final + ' €'
        };
      });

      this.chartParMapCategories = categories;
    },
    async handleGetDepartementSVG() {
      //this.departmentsListe = null;
      if (!this.filterOption) this.depot_stockage = 'Tous les dépots';
      var params = new FormData();
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.selectedLocations.length) {
        params.append(
          'departement[0]',
          parseInt(this.selectedLocations[this.selectedLocations.length - 1])
        );
        //this.selectedLocations = [];
      } else if (
        this.depot_stockage !== 'Tous les dépots' &&
        this.filterOption
      ) {
        params.append('depot_stockage', this.depot_stockage);
      }

      const response = await this.fetchStatsParMap(params);

      if (response.succes) {
        for (let i = 0; i < this.$el.querySelectorAll('path').length; i++) {
          // Clean location
          this.$el.querySelectorAll('path')[i].removeAttribute('aria-checked');

          if (
            this.$el.querySelectorAll('path')[i].id ==
              this.selectedLocations[this.selectedLocations.length - 1] &&
            !this.$el
              .querySelectorAll('path')
              [i].getAttribute('aria-checked') &&
            this.$el
              .querySelectorAll('path')
              [i].getAttribute('aria-checked') !== 'true'
          ) {
            // Add location

            this.$el
              .querySelectorAll('path')
              [i].setAttribute('aria-checked', true);
          }
        }
        this.handleGetAllDataGlobal();
      }

      const seriesDossierPayer = [];
      const seriesDossierAttent = [];
      const seriesDepartementAttent = [];
      const seriesDepartementPayer = [];
      const categories = [];
      const seriesChartDepot = [];
      const seriesChartDepotMontant = [];

      this.getStatsDataPerMap.forEach(item => {
        categories.push(
          this.getSelectedLocationName(FranceDepartement, item.departement)
        );
        seriesDossierPayer.push(item.paye);
        seriesDossierAttent.push(item.attent);
        seriesDepartementAttent.push(
          item.montant_final_attent.replace(/\s/g, '')
        );
        seriesDepartementPayer.push(item.montant_final_paye.replace(/\s/g, ''));
      });

      this.tableDepartementItems = this.getStatsDataPerMap.map(item => {
        seriesChartDepot.push(parseInt(item.paye), parseInt(item.attent));
        seriesChartDepotMontant.push(
          parseInt(item.montant_final_paye_int),
          parseInt(item.montant_final_attent_int)
        );

        return {
          departement: item.departement,
          depot_stockage: item.depot_stockage,
          paye: item.paye,
          attent: item.attent,
          total: item.total,
          montant_final_attent: item.montant_final_attent + ' €',
          montant_final_paye: item.montant_final_paye + ' €',
          montant_final: item.montant_final + ' €'
        };
      });

      this.chartDepartementNbrDossierSeriesSvg =
        seriesChartDepot.length == 2 ? seriesChartDepot : [0, 0];

      this.seriesChartMontantDepotSvg =
        seriesChartDepotMontant.length == 2 ? seriesChartDepotMontant : [0, 0];

      this.chartParMapCategories = categories;
    },

    AddLocation(event) {
      this.depot_stockage = event ? event : '';

      // this.handleGetDepartement();
      // this.handleGetAllDataGlobal();
      this.hideGlobalBlock = false;
    },
    updateChart() {
      this.currentState = !this.currentState;
    },
    updateChartFacture() {
      this.currentStateFacture = !this.currentStateFacture;
    },
    updateChartDepot() {
      this.currentStateDepot = !this.currentStateDepot;
    },
    onReadyDossier: function(instance, CountUp) {
      const that = this;
      instance.update(
        that.getStatsTotalProject ? that.getStatsTotalProject : 0
      );
    },
    onReadyNombre: function(instance, CountUp) {
      const that = this;
      instance.update(
        that.getStatsNombreFactureTotale
          ? parseInt(that.getStatsNombreFactureTotale.replace(/\s/g, ''))
          : 0
      );
    },
    onReadyMontant: function(instance, CountUp) {
      const that = this;
      instance.update(
        that.getStatsSommeFactureTotale
          ? parseInt(that.getStatsSommeFactureTotale.replace(/\s/g, ''))
          : 0
      );
    },
    allfetchStatsParMap() {
      var params = new FormData();
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.selectedLocations)
        params.append('departement[0]', parseInt(this.selectedLocations[0]));
      this.fetchStatsParMap(params);
    },
    allfetchStatsParMapGlobal() {
      var params = new FormData();
      this.selectedLocations = [];
      if (this.year) params.append('year', this.year);
      if (this.month) params.append('month', this.month);
      if (this.week) params.append('week', this.week);
      if (this.filiale) params.append('filiale', this.filiale);
      if (this.depot) params.append('depot', this.depot);
      if (this.depot_stockage !== 'Tous') {
        params.append('depot_stockage', this.depot_stockage);
      }
      this.fetchStatsParMapGlobal(params);
    },
    setLocalStorageStats() {
      localStorage.setItem(
        'stats',
        JSON.stringify({
          year: this.year,
          month: this.month,
          week: this.week,
          filiale: this.filiale,
          depot: this.depot,
          support: this.support
        })
      );
    }
  },
  async mounted() {
    this.loderSociete = true;
    await this.getFamilles();
    const response = await this.getFilialsOfConnectedResponsable({
      minimum: 'yes'
    });
    if (response.success) {
      if (this.getFiliaeOfResponsable.length) {
        this.societe.push({
          text: this.getFiliaeOfResponsable[0].name,
          value: this.getFiliaeOfResponsable[0].id
        });
        this.loadStatsAcheteur();
        this.loadStatsVendeur();
      }
    }
    if (response.success) {
      this.loderSociete = false;
    } else {
      this.loderSociete = false;
    }
    this.checkRole();
    const currentYear = moment().year();
    this.year = currentYear;

    if (localStorage.getItem('stats')) {
      this.year = JSON.parse(localStorage.getItem('stats')).year;
      this.month = JSON.parse(localStorage.getItem('stats')).month;
      this.depot = JSON.parse(localStorage.getItem('stats')).depot;
      this.week = JSON.parse(localStorage.getItem('stats')).week;
      this.support = JSON.parse(localStorage.getItem('stats')).support;
      this.filiale = JSON.parse(localStorage.getItem('stats')).filiale;
    } else {
      this.year = currentYear;
      this.setLocalStorageStats();
    }

    for (var i = 2019; i <= currentYear; i++) {
      this.years.push({ value: i, text: i });
    }

    // this.handleGetDepartement();
    // this.allfetchStatsParMapGlobal();
    // this.featchDepotForYearTH(this.year);
    // this.fetchMonthForYearTH(this.year);
    // this.fetchSemaineValiderTh({ year: this.year });
    // this.loadStats();
    // this.loadStatsPerMonth();
    // this.loadStatsPerFiliale();
    // this.fetchAllDepartements();
  }
};
</script>
<style lang="scss">
.select-vue-component-style.dep .multiselect__tags,
.dep .multiselect__tag {
  font-size: 13px;
}
.select-vue-component-style.dep .multiselect__option--highlight {
  background: #9799d64d;
  border-radius: 0;
  color: #333;
}
.select-vue-component-style.dep .multiselect__option--selected {
  border-radius: 0;
  background: #258ecf;
  color: #fff;
}
.select-vue-component-style.dep .multiselect__option {
  min-height: 20px;
  line-height: 9px;
  font-size: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
}
</style>
<style lang="scss">
.svg-map {
  width: 100%;
  max-width: 100%;
  height: 100%;
  stroke: #fff;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: fill;
  max-height: 85%;
}
.stats-row-panel {
  display: flex;
}

.svg-map__location {
  fill: rgb(173, 225, 251);
  cursor: pointer;
}
.svg-map__location.disabled {
  fill: gray !important;
  cursor: no-drop;
}
.svg-map__location:focus {
  outline: 0;
}
.svg-map .svg-map__location:hover {
  fill: #0c669f !important;
  outline: 0;
  transition: 0.5s;
}
.svg-map__location[aria-checked='true'] {
  fill: rgb(37, 142, 207) !important;
}
.examples__block__map__tooltip {
  position: fixed;
  width: auto;
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}
.child--1 .custom-table-style .thead-light th,
.child--1 .custom-table-style td {
  font-size: 10px;
  min-width: 90px;
  padding: 5px 4px;
}
.child--100 .custom-table-style .thead-light th,
.child--100 .custom-table-style td {
  font-size: 10px;
  min-width: 138px;
  padding: 5px 4px;
}
.child--100 .custom-table-style {
  width: 1300px;
  margin: 0px auto;
}
</style>
<style scoped lang="scss">
* {
  font-family: 'Montserrat', sans-serif;
}
.time-style {
  margin-top: 5px;
  background: #7675bf;
  font-size: 5px;
  width: 12px;
  height: 11px;
  border-radius: 50%;
  padding: 1px;
}
.time-style-global {
  margin-top: 5px;
  background: #7675bf;
  font-size: 5px;
  width: 19px;
  height: 17px;
  border-radius: 50%;
  padding: 1px;
  color: white;
}
.check-icon-style {
  float: right;
  margin-top: 4px;
  // margin-right: 10px;
  color: #fff;
  margin-left: 4px;
  font-size: 12px;
}
.side-bar-position {
  position: fixed;
  width: 205px;
}
.aligne-empty-bills {
  display: flex;
  color: red;
  justify-content: center;
}
.aligne-chiffres {
  display: flex;
  justify-content: right;
  margin-right: 5px;
}
.filtre-mobile {
  background-color: #fff;
  border-radius: 20px;
}
.page-static-style {
  // background-color: #f6f5fb;
  // overflow-x: scroll;
  height: calc(100vh - 116px);
}
.right-side {
  height: calc(100vh - 164px);
  overflow-y: auto;
}
.color-icon {
  color: #fff;
}
.style-static-page {
  background-color: #fff;
  border-radius: 20px;
}
.white-background {
  background-color: #fff;
}
.calcul-general-label {
  background-color: #8d8cb7;
  border-radius: 10px 10px 0px 0px;
}
.Montant-labels {
  background-color: #f0f1ff;
  border-radius: 10px 0px 0px 10px;
}
.chiffre-label {
  background-color: #f2f2f2;
  border-radius: 0px 10px 10px 0px;
}
.font-style {
  font-size: 12px;
}
.button-filtre-style {
  color: #fff;
  background-color: #8d8cb7;
  border-radius: 20px;
  border: none;
}
.chiffre-affaire-label {
  background-color: #e4e5f9;
  border-radius: 10px 0px 0px 10px;
}
@media screen and (max-width: 576px) {
  .filtres {
    display: none;
  }
  .list-societe {
    display: none;
  }
  .right-side {
    max-width: 450px;
  }
}
@media screen and (min-width: 576px) {
  .menu-corner {
    display: none;
  }
}
.alert-div-style {
  background-color: #fff;
  height: 150px;
  border-radius: 30px;
  box-shadow: 3px 3px 3px 3px #d7d6dc;
}
.text-alert-color {
  color: red;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #7978aa;
  border-radius: 7px;
}
.li-societe-style-active-filtre {
  // border: 1px solid black;
  /* padding: 3px; */
  float: left;
  margin: 1px;
  border-radius: 10px;
  display: inline;
  cursor: pointer;
  background-color: #a86709;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.Search-style-component {
  .search-box {
    // position: relative;
    // top: 0;
    // right: 0px;
    // background-color: #842828;
    // padding: 5px;
    // color: #871c1c;
    // border: none;
    // border-radius: 19px;
    // margin-bottom: 30px;s
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    position: relative;
    top: 0;
    right: 0px;
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    // border: 1px solid #9393bc;
    border-radius: 20px 20px;
    background-color: #f9f9f9;
    font-size: medium;
    // width: 263px;
    .icon {
      position: absolute;
      font-size: 14px;
      top: 8px;
      left: 14px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-right {
      position: absolute;
      font-size: 12px;
      top: 8px;
      right: 8px;
      color: #4d4bac;
      &:active,
      &:focus {
        color: #2dabe2;
      }
    }
    .icon-loader {
      color: #2dabe2;
    }
    .search-input {
      background-color: #f9f9f9;
      height: 18px;
      width: 160px;
      padding: 3px 3px 3px 33px;
      border-radius: 3px;
      box-sizing: border-box;
      box-shadow: none;
      border: none;
      opacity: 0.5;
      outline: none;
      color: black;
      font-size: 13px;

      &:focus,
      &:active {
        opacity: 1;
      }
    }
  }

  .serachLocale {
    background-color: #f9f9f9;

    .search-input {
      background-color: #f9f9f9;
    }
  }
  label {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #5d5d5d;
  }
}
.space-societe {
  background-color: #ffffff;
  width: 100%;
  border-radius: 20px;
}
.ul-societe-style {
  height: calc(100vh - 239px);
  overflow-x: scroll;
  background-color: #ffffff;
  list-style-type: none;
}
.ul-societe-style-display {
  overflow-x: scroll;
  background-color: #ffffff;
  list-style-type: none;
}
.li-societe-style {
  // border: 1px solid black;
  padding: 3px;
  float: left;
  margin: 3px;
  border-radius: 10px;
  display: inline;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  width: 95%;
  font-size: 12px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.li-societe-style-active {
  // border: 1px solid black;
  padding: 3px;
  float: left;
  margin: 3px;
  border-radius: 10px;
  display: inline;
  cursor: pointer;
  background-color: #9799d6;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.child--1 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 60%;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 20px;
  margin-right: 10px;

  .chart-title {
    flex-direction: column;
    position: relative;

    padding: 12px 12px !important;
    border-radius: 0 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
    font-size: 16px;
    border-bottom: 4px solid #8d8cb7;
  }
  .btnchart {
    display: flex;
    // justify-content: end;
  }
  .btn {
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 12px;
    will-change: transform;
    color: #fff;
    background-color: #8d8cb7;
    border-color: #8d8cb7;
    padding: 0px 10px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    opacity: 0.8;
  }
}
.child--2 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 38%;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 20px;
  margin-right: 10px;
  .chart-title {
    flex-direction: column;
    position: relative;

    padding: 12px 12px !important;
    border-radius: 0 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
    font-size: 16px;
    border-bottom: 4px solid #8d8cb7;
  }
  .btnchart {
    display: flex;
    // justify-content: end;
  }
  .btn {
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 1rem;
    will-change: transform;
    color: #fff;
    background-color: #8d8cb7;
    border-color: #8d8cb7;
    padding: 0px 10px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    opacity: 0.8;
  }
  .chargement {
    position: absolute;
    right: 52px;
    top: 11px;
    opacity: 0.5;
  }
}
.child--100 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 100%;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: 20px;

  .chart-title {
    flex-direction: column;
    position: relative;

    padding: 12px 12px !important;
    border-radius: 0 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
    font-size: 16px;
    border-bottom: 4px solid #8d8cb7;
  }
  .btnchart {
    display: flex;
    // justify-content: end;
  }
  .btn {
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 12px;
    will-change: transform;
    color: #fff;
    background-color: #8d8cb7;
    border-color: #8d8cb7;
    padding: 0px 10px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    opacity: 0.8;
  }
  .chargement {
    position: absolute;
    right: 152px;
    top: 11px;
    opacity: 0.5;
  }
}

.child--3 {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 800px;
  padding: 25px;
  background-color: #fff;
  border-radius: 3%;
  margin-bottom: 20px;
}

.data-style {
  position: relative;

  .examples__block__info__item {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #5d5d5d;
    ul {
      overflow-y: auto;
      height: calc(90vh - 200px);
    }
    .error-message .error {
      font-size: 13px;
    }
  }
}
.stats-page .stats-wrapper .stats-body .stats-content .maps-form-style {
  background-color: #ffffff;
  border-radius: 25px;
  width: 100%;
  padding: 7px;
  display: flex;
  .maps {
    width: 50%;
    flex: initial;
    min-width: auto;
  }
  .map-chart {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listeselect {
    width: 38%;
    flex: initial;
    min-width: auto;
  }
}
.v_Panel {
  box-shadow: 0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%),
    0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%);
  width: 800px;
  padding: 25px;
  background-color: #fff;
  border-radius: 3%;
  margin-bottom: 20px;
  .left {
    float: left;
  }
}
.stats-page .stats-wrapper .stats-body .stats-content {
  background-color: transparent;
}
.body-box-maps {
  display: flex;
  background-color: #f5f4fa;
  height: calc(100vh - 109px);
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 27px;
  .data-style ul::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .data-style ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eae7f6;
    border-radius: 10px;
  }
  .data-style ul::-webkit-scrollbar-thumb {
    background: #b4b3d0;
    border-radius: 7px;
  }
}
.red-title {
  color: #8d8cb7;
  font-size: 15px;
  font-weight: bold;
}
.examples__block__info__item .chart-title {
  background-color: #f5f5ff;
  padding: 7px 23px;
  border-radius: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  list-style: none;
  font-size: 12px;
  display: block !important;
}
.chart-title.global {
  display: block !important;
  font-size: 15px;
  line-height: 28px;
}
.switchDashbord {
  position: absolute;
  right: 13px;
  font-size: 12px;
  font-weight: bold;
  color: #8d8cb7;
}
.table-rapport-style {
  padding: 0px !important;
}
</style>
<style lang="scss">
.stats-page {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .stats-wrapper {
    .stats-filter {
      height: 42px;
      width: 100%;
      background-color: #f5f4fa;
      border-radius: 41px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px 10px;
    }
    .stats-body {
      display: flex;
      background-color: #f5f4fa;
      height: calc(100vh - 109px);
      margin-top: 3px;
      border-radius: 28px;
      position: relative;
      padding: 11px;
      .stats-content::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      .stats-content::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #eae7f6;
        border-radius: 10px;
      }
      .stats-content::-webkit-scrollbar-thumb {
        background: #b4b3d0;
        border-radius: 7px;
      }
      .stats-content {
        background-color: #ffffff;
        border-radius: 25px;
        width: 100%;
        position: relative;
        padding: 7px;
        overflow: auto;

        .stats-content-row {
          display: flex;
          flex-wrap: wrap;
        }
        .stats-content-col {
          flex: 1;
          min-width: 400px;

          &.mini-col {
            margin: 0px 5px 20px 10px;
            box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%);
            -webkit-transition: all 0.3s ease-in-out;
            &:hover {
              -webkit-box-shadow: 0 7px 14px rgb(50 50 93 / 10%),
                0 3px 6px rgb(0 0 0 / 8%);
              box-shadow: 0 7px 14px rgb(50 50 93 / 10%),
                0 3px 6px rgb(0 0 0 / 8%);
              -webkit-transform: translateY(-1px);
              transform: translateY(-2px);
              cursor: pointer;
            }
          }
          &.mini-col:first-child {
            margin-left: 0px;
          }
        }
        .chart-title {
          display: flex;
          background-color: #f5f5ff;
          padding: 7px 23px;
          border-radius: 18px;
          margin-bottom: 10px;
          margin-top: 10px;

          .chargement {
            margin-bottom: -5px;
            margin-left: 10px;
            .spinner-border {
              width: 22px;
              height: 22px;
            }
          }
        }

        .total-stats {
          margin: 0;
          background: #fff;
          border-radius: 0;

          width: 100%;
          padding: 21px 12px;
          display: flex;
          .total-stats-title {
            height: 60px;
            background: transparent;
            color: #626262;
            padding: 10px;
            display: flex;
            justify-content: left;
            align-items: center;
            font-size: 15px;
          }
          .total-stats-value {
            color: black;
            font-size: 15px;
            padding: 10px;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
          }
          .left-stats {
            width: 90%;
          }
          .right-stats {
            display: flex;
            // justify-content: end;
            // align-items: end;
          }
        }
      }
    }
  }
  .bold {
    font-weight: bold;
  }
}
</style>
